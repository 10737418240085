<template>
  <div>
    <TopNav/>
    <div class="d-flex flex-column justify-content-center align-items-center " style="height: 100vh; margin-top:60px;">
      <h2 class="title">Welcome to sofia Ai</h2>
      <p>Your favorite medical assistant</p>
      <div class="input-group">
        <label for="patientName" class="input-label">Patient Name</label>
        <input v-model="ehrPatientName" id="patientName" type="text" required placeholder="Enter patient name" class="input-field" />
      </div>
      <div class="route-options d-flex">
        <label class="route-label mx-4">
          <input type="radio" v-model="selectedRoute" value="record" class="route-radio form-check-input" checked/>
          Single language
        </label>
        <label class="route-label mx-4">
          <input type="radio" v-model="selectedRoute" value="translate" class="route-radio form-check-input" />
          Multi language
        </label>
      </div>
      <div v-if="selectedRoute === 'translate'" class="language-selection row mb-2" style="width: 40%;i">
        <div class="form-group col-md-6">
          <label for="doctor-language">Doctor Language</label>
          <select v-model="doctorLanguage" id="doctor-language" class="form-select">
            <option value="en">English</option>
            <option value="es">Spanish</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label for="patient-language">Patient Language</label>
          <select v-model="patientLanguage" id="patient-language" class="form-select">
            <option value="en">English</option>
            <option value="es">Spanish</option>
          </select>
        </div>
      </div>
      <div class="input-group">
        <label for="DOB" class="input-label">Date of Birth</label>
        <input v-model="ehrPatientDOB"  :max="maxBirthDate" @focus="preventOldYears" id="DOB" type="date" required placeholder="Enter Date of Birth" class="input-field" />
      </div>
      <div class="input-group">
        <label for="DOC" class="input-label">Date of Complaint</label>
        <input v-model="DOC" id="DOC" type="date" required placeholder="Enter Date of Complaint" class="input-field" />
      </div>
      <div class="input-group">
        <label for="DOS" class="input-label">Date of Service</label>
        <input v-model="DOS" id="DOS"   type="date" required placeholder="Enter Date of Service" class="input-field" />
      </div>
      <div class="input-group">
        <label for="doctorName" class="input-label">Doctor Name</label>
        <input v-model="doctorName" id="doctorName" type="text" required placeholder="Enter Doctor name" class="input-field" />
      </div>
      <div>
        <button @click="handleSubmit" :disabled="!ehrPatientName || !selectedRoute || !ehrPatientDOB" class="submit-btn btn btn-primary">Start</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick ,computed } from 'vue';
import { useWebSocket } from '../utils/useWebSocket';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import {setupJWTInterceptor} from '@/services/axios';
import TopNav from '../layouts/topNav.vue'

const JWTinterceptor = setupJWTInterceptor();
const store = useStore();
const selectedRoute = ref('record');
const doctorLanguage = ref('en'); 
const patientLanguage = ref('es'); 
const token = localStorage.getItem('token');
const webSocket = ref(null);
const router = useRouter();
const route = useRoute();
const toast = useToast();
const DOS = new Date().toISOString().slice(0, 10);
// const DOB = ref('');
const DOC = ref('');
const doctorName = route.query.ehrDoctorName || ref('');
const ehrEncounterId =  route.query.ehrEncounterId;
const ehrPatientName = route.query.ehrPatientName || ref('');
const ehrPatientDOB =  route.query.ehrPatientDOB|| ref('');
const ehrOrgName =  route.query.ehrOrgName|| ref('');
const ehrPatientId =  route.query.ehrPatientId|| ref('');

const maxBirthDate  = computed(() => {
  const today = new Date();
  today.setFullYear(today.getFullYear() - 18);
  return today.toISOString().slice(0, 10); 
});

function preventOldYears(event) {
  const dateInput = event.target;
  dateInput.max = maxBirthDate.value;
}

const handleSubmit = async () => {
  const topicId = await getNewTopicId(); 
  if (topicId) {
    const dialogId ="2";
    const doctorLang= doctorLanguage.value
    const patientLang= patientLanguage.value
    const patientNameV = ehrPatientName
    const wsUrl  = `${process.env.VUE_APP_SOFA_WS}${topicId}/${dialogId}/${patientNameV}`;
if (selectedRoute.value === 'record') {
    store.commit('setPatientName', patientNameV);
    store.commit('setTopicId', topicId);
    store.commit('setDOB',ehrPatientDOB)
    store.commit('setDOC',DOC)
    store.commit('setDOS',DOS)
    if(ehrPatientId.value === ""){
      const data = { topicId, patientNameV, ehrPatientName, view: 'record' };
      store.dispatch('navigation/saveNavigationData', data);
      await router.push({ name: 'encounter' });
    }else{
      const data = {   patientNameV ,ehrEncounterId, ehrPatientName,ehrPatientId, ehrOrgName,view: 'record'};
      store.dispatch('navigation/saveNavigationData', data);
    await router.push({ name: 'encounter'});
    }
  } else if (selectedRoute.value === 'translate') {
    store.commit('setPatientName', patientNameV);
    store.commit('setTopicId', topicId);
    store.commit('setDOB',ehrPatientDOB)
    store.commit('setDOC',DOC)
    store.commit('setDOS',DOS)
    const data = { topicId, patientNameV, doctorLang,patientLang, view: 'translate' };
    console.log(doctorLang,patientLang)
    store.dispatch('navigation/saveNavigationData', data);
    console.log(data)
    await router.push({ name: 'encounter' });
  } else {
    console.warn('No matching route');
    toast.warning('No matching route');
    return;
  }
  await nextTick();
    const handleMessage = (data) => {
      console.log('Message received from WebSocket:', data);
    };
    webSocket.value = useWebSocket(wsUrl, token,handleMessage);
  }
};

const getNewTopicId = async () => {
    const response = await JWTinterceptor.post(`${process.env.VUE_APP_SOFA_API}last_chat`,{
      encounter_id: ehrEncounterId
    });
    return response.data.responseData.id; 
};
</script>

<style scoped>

.title {
  font-weight: 600;
  text-align: center;
  color: #1f86ae ;
}

.input-group {
  margin-bottom: 15px;
  width: 40%;
}

.input-label {
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
  color: #555;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

.route-options {
  margin-bottom: 20px;
}

.route-label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  padding-left: 15px;
}

.route-radio {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 5px;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
}

.submit-btn {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 10rem;
  background-color: #4caf50;
  color: white;
  margin-right: 10px;
}
.input-field{
  border-radius: 8px !important;
}
</style>
