<template>
  <div class="p-6 max-w-2xl mx-auto">
    <h1 class="text-2xl font-bold mb-4">Real-Time Transcription</h1>
    <div class="mb-4">
      <button
        @click="startStreaming"
        :disabled="isStreaming"
        class="px-4 py-2 btn btn-primary rounded-lg mr-2"
      >
        Start
      </button>
      <button
        @click="stopStreaming"
        :disabled="!isStreaming"
        class="px-4 py-2 btn btn-danger rounded-lg"
      >
        Stop
      </button>
    </div>
    <div class="border rounded-lg p-4 h-64 overflow-y-auto bg-gray-50">
      <p class="text-gray-800">{{ combinedTranscript }}</p>
    </div>
    <p v-if="errorMessage" class="text-red-500 mt-4">{{ errorMessage }}</p>
  </div>
</template>

<script setup>
import { ref } from "vue";

const transcripts = ref([]);
const isStreaming = ref(false);
const errorMessage = ref("");
const combinedTranscript = ref(""); // Combines all transcripts into a paragraph
let websocket = null;
let reconnectTimeout = null; // For reconnect logic

// Start WebSocket connection
const startStreaming = () => {
  errorMessage.value = "";
  transcripts.value = [];
  combinedTranscript.value = "";
  isStreaming.value = true;

  connectWebSocket();
};

// WebSocket connection logic with auto-reconnect
const connectWebSocket = () => {
  websocket = new WebSocket("wss://ai-stage.ensofia.app:9443/fast/stream_ws");

  websocket.onopen = () => {
    console.log("WebSocket connection established.");
    startRecording();
  };

  websocket.onmessage = (event) => {
    const data = JSON.parse(event.data);
    console.log(event);

    // Avoid duplicate final transcripts
    const last = transcripts.value[transcripts.value.length - 1];
    if (last && last.transcript === data.transcript && last.isFinal === data.is_final) {
      return;
    }

    // Add or update interim result
    if (data.is_final) {
      transcripts.value.push(data.transcript);
      combinedTranscript.value = transcripts.value.join(" "); // Combine transcripts
    }
  };

  websocket.onerror = (error) => {
    console.error("WebSocket error:", error);
    errorMessage.value = "An error occurred with the WebSocket connection.";
    stopStreaming();
  };

  websocket.onclose = (event) => {
    console.log("WebSocket connection closed:", event);

    // Attempt to reconnect if the streaming was ongoing
    if (isStreaming.value) {
      reconnectTimeout = setTimeout(() => {
        console.log("Reconnecting WebSocket...");
        connectWebSocket();
      }, 1000);
    }

    stopRecording();
  };
};

// Stop WebSocket connection
const stopStreaming = () => {
  if (websocket) {
    websocket.close();
    websocket = null;
  }
  isStreaming.value = false;

  if (reconnectTimeout) {
    clearTimeout(reconnectTimeout);
    reconnectTimeout = null;
  }
};

// Web Audio API: Audio recording logic
let mediaRecorder;
const audioContext = new (window.AudioContext || window.webkitAudioContext)();
const startRecording = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const source = audioContext.createMediaStreamSource(stream);
    const processor = audioContext.createScriptProcessor(4096, 1, 1);

    source.connect(processor);
    processor.connect(audioContext.destination);

    processor.onaudioprocess = (event) => {
      if (websocket && websocket.readyState === WebSocket.OPEN) {
        const audioData = event.inputBuffer.getChannelData(0);
        const audioBuffer = new Int16Array(audioData.length);
        for (let i = 0; i < audioData.length; i++) {
          audioBuffer[i] = audioData[i] * 0x7fff; // Convert float to 16-bit PCM
        }
        websocket.send(audioBuffer.buffer);
      }
    };

    mediaRecorder = { stream, source, processor };
  } catch (err) {
    console.error("Error accessing microphone:", err);
    errorMessage.value = "Could not access the microphone.";
    stopStreaming();
  }
};

const stopRecording = () => {
  if (mediaRecorder) {
    mediaRecorder.stream.getTracks().forEach((track) => track.stop());
    mediaRecorder.processor.disconnect();
    mediaRecorder.source.disconnect();
    mediaRecorder = null;
  }
};
</script>

<style scoped>
h1 {
  color: #1f2937;
}
</style>
