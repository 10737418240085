// sync google STT
import { ref } from 'vue';
import axios from 'axios';
import { useToast } from 'vue-toastification';
const toast = useToast();

export function useRecorder(language ) {
  const isRecording = ref(false);
  const audioChunks = ref([]);
  const transcription = ref('');
//   const translation = ref('');
  const error = ref(null);
  let mediaRecorder = null;
//   let audioInstance = null;
//   const isSpeaking = ref(false);

  const startRecording = async () => {
    isRecording.value = true;
    error.value = null;
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm;codecs=opus' });
    
    mediaRecorder.ondataavailable = (event) => {
      audioChunks.value.push(event.data);
    };
    
    mediaRecorder.onstop = handleStop;
    mediaRecorder.start();
  };

  const stopRecording = async () => {
    isRecording.value = false;
    mediaRecorder.stop();
  };

  const handleStop = async () => {
    const audioBlob = new Blob(audioChunks.value, { type: 'audio/webm;codecs=opus' });
    audioChunks.value = [];
    const linear16Audio = await convertToLinear16(audioBlob);
    const base64Audio = await convertToBase64(linear16Audio);

    try {
      transcription.value = await transcribeAudio(base64Audio, audioBlob);
    //   translation.value = await translateText(transcription.value);
      // await speakText(translation.value);
      console.log(transcription.value)
    } catch (err) {
      console.error(err.message);
      toast.error(err.message);
    }
  };

  const convertToBase64 = (blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.readAsDataURL(blob);
    });
  };
  const convertToLinear16 = (blob) => {
    return new Promise((resolve, reject) => {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const reader = new FileReader();
      reader.onload = () => {
        audioContext.decodeAudioData(reader.result, (buffer) => {
          const wavBuffer = audioBufferToWav(buffer);
          const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });
          resolve(wavBlob);
        }, reject);
      };
      reader.readAsArrayBuffer(blob);
    });
  };
  const audioBufferToWav = (buffer) => {
    const numOfChan = buffer.numberOfChannels;
    const length = buffer.length * numOfChan * 2 + 44;
    const buffer16 = new ArrayBuffer(length);
    const view = new DataView(buffer16);
    const channels = [];
    let offset = 0;
    let pos = 0;

    setUint32(0x46464952); // "RIFF"
    setUint32(length - 8); // file length - 8
    setUint32(0x45564157); // "WAVE"

    setUint32(0x20746d66); // "fmt " chunk
    setUint32(16); // length = 16
    setUint16(1); // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(buffer.sampleRate);
    setUint32(buffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
    setUint16(numOfChan * 2); // block-align
    setUint16(16); // 16-bit (LINEAR16)

    setUint32(0x61746164); // "data" - chunk
    setUint32(length - pos - 4); // chunk length

    for (let i = 0; i < buffer.numberOfChannels; i++) {
      channels.push(buffer.getChannelData(i));
    }

    while (pos < length) {
      for (let i = 0; i < numOfChan; i++) {
        const sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
        view.setInt16(pos, sample < 0 ? sample * 0x8000 : sample * 0x7FFF, true); // PCM
        pos += 2;
      }
      offset++; // next source sample
    }

    return buffer16;

    function setUint16(data) {
      view.setUint16(pos, data, true);
      pos += 2;
    }

    function setUint32(data) {
      view.setUint32(pos, data, true);
      pos += 4;
    }
  };

  const transcribeAudio = async (base64Audio, audioBlob) => {
    const apiKey = 'AIzaSyAM6aY_lKcQFuL9LuQ4oMv_m7KRPyqLG4w';
    const languageCode = language.value === 'en' ? 'en-US' : 'es-ES';
    const audioChannelCount = await getAudioChannelCount(audioBlob);
  
    const response = await axios.post(
      `https://speech.googleapis.com/v1/speech:recognize?key=${apiKey}`,
      {
        config: {
          encoding: 'LINEAR16', 
          // sampleRateHertz: 48000,
          languageCode,
          audioChannelCount,
        },
        audio: {
          content: base64Audio,
        },
      }
    );
  
    if (response.data.results) {
      return response.data.results
        .map((result) => result.alternatives[0].transcript)
        .join('\n');
    } else {
      toast.error('No transcription results');
      throw new Error('No transcription results');
    }
  };

  const getAudioChannelCount = (blob) => {
    return new Promise((resolve) => {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const reader = new FileReader();
      reader.onload = () => {
        audioContext.decodeAudioData(reader.result, (buffer) => {
          resolve(buffer.numberOfChannels);
        });
      };
      reader.readAsArrayBuffer(blob);
    });
  };

//   const translateText = async (text) => {
//     const apiKey = 'AIzaSyAM6aY_lKcQFuL9LuQ4oMv_m7KRPyqLG4w'; 
//     const targetLanguage = language.value === 'en' ? 'es' : 'en';

//     const response = await axios.post(
//       `https://translation.googleapis.com/language/translate/v2`,
//       null,
//       {
//         params: {
//           q: text,
//           target: targetLanguage,
//           key: apiKey,
//         },
//       }
//     );

//     if (response.data.data && response.data.data.translations) {
//       return response.data.data.translations[0].translatedText;
//     } else {
//       toast.error(('No transcription results'))
//       throw new Error('No translation results');
//     }
//   };

//   const speakText = async (text) => {
   
//     const apiKey = 'AIzaSyAM6aY_lKcQFuL9LuQ4oMv_m7KRPyqLG4w';
//     const languageCode = language.value === 'en' ? 'es-US' : 'en-US';
//     const voiceName = language.value === 'en' ? 'es-US-Neural2-A' : 'en-US-Neural2-E';

//     const response = await axios.post(
//       `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`,
//       {
//         input: { text },
//         voice: {
//           languageCode,
//           name: voiceName,
//         },
//         audioConfig: {
//           audioEncoding: 'MP3',
//           effectsProfileId: ['handset-class-device'],
//           pitch: 0,
//           speakingRate: 1,
//         },
//       }
//     );

//     const audioContent = response.data.audioContent;
//     const audio = new Audio(`data:audio/mp3;base64,${audioContent}`);
//     audioInstance = audio; // Store the audio instance globally for stopping
//   isSpeaking.value = true; // Mark as speaking

//   audio.play();
  
//   // Once the audio ends, reset the state
//   audio.onended = () => {
//     isSpeaking.value = false;
//   };
//   };
//   const stopSpeaking = () => {
//     if (audioInstance) {
//       audioInstance.pause(); // Pause the audio
//       audioInstance.currentTime = 0; // Reset the audio to the start
//       isSpeaking.value = false; // Reset speaking state
//       console.log('Speech stopped');
      
//     }
//   };

  return {
    isRecording,
    transcription,
    // translation,
    error,
    startRecording,
    stopRecording,
    // speakText,
    // stopSpeaking,
  };
}
