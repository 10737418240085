<template>
  <div v-if="currentView === 'record'">
    <RecordPage :socket="socket" :sendMessage="sendMessage" :handleMessage="processMessage" @send="goToDetails" />
  </div>
  <div v-if="currentView === 'translate'">
    <TranslatePage :socket="socket" :sendMessage="sendMessage" :handleMessage="processMessage" @send="goToDetails" />
  </div>
  <div v-else-if="currentView === 'details'">
    <TopNav :patientName="patientName" />
    <div style="margin-top: 5rem;display: flex;flex-direction: column;height: 90vh;overflow: hidden;">
      <div ref="chatContainer" class="chat-container">
        <div v-for="message in data.responseData" :key="message.id" class="mt-3" :data-id="message.id" >
          <div class="card-body">
            <div :class="[ 'message-body', message.isuser ? 'message-color-user' : 'message-color-sofia', 'text-white', 'p-3', 'rounded',]" >
              <p v-html="formatMessage(message.message)"></p>
              <p class="timestamp">
                {{ new Date(message.created_at).toLocaleString() }}
              </p>
              <div v-if="!message.isuser" class="message-actions d-flex gap-2 mt-2" >
                <button @click="toggleSpeak(message)" class="btn-color">
                  <i v-if="message.isLoading" class="fa-solid fa-spinner fa-spin" ></i>
                  <i v-else :class="message.isSpeaking ? 'fa-solid fa-pause' : 'fa-solid fa-volume-up'" ></i>
                </button>
                <button @click="copyToClipboard(message.message)" class="btn-color">
                  <i class="fas fa-copy"></i>
                </button>
                <button @click="sendPdf(message.message)" class="btn-color">
                  <i class="fas fa-file-pdf"></i>
                </button>
                <button @click="shareMessage(message.message)" class="btn-color" >
                  <i class="fas fa-share-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-container bg-light">
        <div v-if="loadingMessage" class="text-center mt-3">
          <i class="fa fa-spinner fa-spin fa-2x"></i>
        </div>
        <div v-if="addNote" class="pt-4 w-100 d-flex">
          <textarea v-model="note" placeholder="Write your note here..." class="form-control me-2" >
          </textarea>
          <span v-if="isSending" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span v-else class="d-flex">
            <button class="btn btn-primary mic-button me-2" @click="toggleRecording">
              <i :class="micIcon"></i>
            </button>
            <button @click="handleButtonClick(note, 'Note')" class="btn btn-primary " >
              <i class="fa-solid fa-circle-arrow-right"></i>
            </button>
          </span>
        </div>
        <div class="d-flex flex-wrap gap-2 justify-content-center py-4 col-12 col-md-10">
          <button v-for="btn in transformedButtons" :key="btn.original" @click="handleButtonClick(btn.label, btn.label)" class="btn btn-info text-white">
            {{ btn.label }}
          </button>
          <button  @click="openWarning"  class="btn btn-info text-white" v-if="!loadingMessage">
            add Note 
          </button>
        </div>
          <!-- Bootstrap Modal -->
          <div v-if="showWarning" class="modal fade show d-block" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header bg-warning text-white">
                  <!-- <h5 class="modal-title">Warning</h5> -->
                  <button type="button" class="btn-close" @click="showWarning = false"></button>
                </div>
                <div class="text-center">
                  <i class="fa-solid fa-triangle-exclamation  text-warning" style="font-size:100px;"></i>
                  <p><strong>Warning!</strong></p>
                  <p>If you add a note now, previous data will be changed.</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" @click="confirmChange">
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, onUpdated, onUnmounted,onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { setupJWTInterceptor } from "@/services/axios";
import TopNav from "../layouts/topNav.vue";
import { useSpeak } from "../utils/useSpeech";
import RecordPage from "./RecordPage.vue";
import TranslatePage from "./TranslatePage.vue";
import { useRecorder } from "../utils/useRecord2";

const JWTinterceptor = setupJWTInterceptor();
// eslint-disable-next-line no-unused-vars
const { speakText, stopSpeaking, isLoading } = useSpeak("es");
const language = ref("en");
const { isRecording, transcription, startRecording, stopRecording } = useRecorder(language);
const store = useStore();
const navigationData = computed(() => store.getters['navigation/getNavigationData']);
const topicId = store.state.topicId;
const data = ref({ responseData: [] });
const loading = ref(true);
const text = ref("");
const dialogId = "2";
const token = localStorage.getItem("token");
const patientName = computed(() => store.state.patientName);
const wsUrl = `${process.env.VUE_APP_SOFA_WS}${topicId}/${dialogId}/${patientName.value}`;
const sentMessages = ref([]);
const receivedResponses = ref([]);
const toast = useToast();
const chatContainer = ref(null);
const buttons = ref([]);
const transformedButtons = ref([]);
const currentView = ref("details");
const socket = computed(() => store.state.websocket.socket);
const loadingMessage = ref(false);
const addNote = ref(false);
const note = ref("" || transcription);
const micIcon = ref("fa-solid fa-microphone");
const showWarning = ref(false);
const isNoteChanged = ref(false);

const sendMessage = (message) => {
  store.dispatch("websocket/sendMessage", message);
  loadingMessage.value = true;
};
const openWarning = () => {
  showWarning.value = true; // Show warning modal
};
const confirmChange = () => {
  addNote.value = !addNote.value; // Toggle addNote only after confirmation
  showWarning.value = false;
};

// Function to process incoming WebSocket messages
const processMessage = (receivedData) => {
  if (receivedData.source === "sofia") {
    receivedResponses.value.push(receivedData.response);
    console.log("Received Sofia response:", receivedData.response);
  } else if (receivedData && receivedData.message) {
    const receivedMessage = {
      id: new Date().getTime(),
      message: receivedData.responseData.message,
      created_at: new Date().toISOString(),
      isuser: false,
    };
    if (data.value && data.value.responseData) {
      data.value.responseData.push(receivedMessage);
    } else {
      data.value = {
        responseData: [receivedMessage],
      };
    }
    console.log("Message received via WebSocket:", receivedMessage);
    receivedResponses.value.push(receivedMessage.message);
  } else {
    console.log("Received unrecognized WebSocket message:", receivedData);
  }
  loadingMessage.value = false;
};
const toggleRecording = () => {
  if (isRecording.value) {
    console.log(isRecording.value);
    stopRecording();
    micIcon.value = "fa-solid fa-microphone";
  } else {
    startRecording();
    micIcon.value = "fa-solid fa-pause";
  }
};
const scrollToBottom = () => {
  if (chatContainer.value) {
    chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
  }
};
onMounted(() => {
  if (navigationData.value.view === "record") {
    currentView.value = "record";
  }else if(navigationData.value.view === "translate"){
    currentView.value = "translate";
  }
  getBtn();
  store.dispatch("websocket/connect", {
    wsUrl,
    token,
    handleMessage: processMessage,
  });
  if (topicId && token) {
    getTopicChat(topicId);
  } else {
    toast.error("Chat ID or Access Token is missing");
    console.error("Chat ID or Access Token is missing");
  }
  scrollToBottom();
});
onUpdated(scrollToBottom);

const formatMessage = (message) => {
  return String(message || "")
    .replace(/\n/g, "<br>")
    .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
};

const goToDetails = () => {
  currentView.value = "details";
  const data = {  view: 'details' };
      store.dispatch('navigation/saveNavigationData', data);
  getTopicChat(topicId);
};

const getTopicChat = async (topicId) => {
  loadingMessage.value = true;
  // alert(topicId)
  try {
    const response = await JWTinterceptor.get(
      `${process.env.VUE_APP_SOFA_API}chat/${topicId}/messages`
    );
    data.value = response.data;
    console.log("Response received in details:", response.data);
    loadingMessage.value = false;
    receivedResponses.value.push(
      JSON.stringify(response.data.responseData.message)
    );
  } catch (e) {
    console.error("Error fetching chat data:", e.message);
    toast.error("Error fetching chat data:", e.message);
  } finally {
    loading.value = false;
  }
};

const sendMsg = async (msg, cat) => {
  if (msg.trim().length > 0) {
    const messagePayload = {
      message: msg.trim(),
      is_draft: 0,
      category_id: cat.trim(),
      chat_id: topicId || 0,
      message_id: "12",
      created_at: new Date().toISOString(),
      isuser: true,
    };
    sendMessage(messagePayload);
    if (data.value && data.value.responseData) {
      data.value.responseData.push({
        id: new Date().getTime(),
        message: messagePayload.message,
        created_at: messagePayload.created_at,
        isuser: messagePayload.isuser,
      });
    } else {
      data.value = {
        responseData: [
          {
            id: new Date().getTime(),
            message: messagePayload.message,
            created_at: messagePayload.created_at,
            isuser: messagePayload.isuser,
          },
        ],
      };
    }
    sentMessages.value.push(messagePayload.message);
  }
};

const sendPdf = async (msg) => {
  try {
    const result = await JWTinterceptor.post(
      `${process.env.VUE_APP_SOFA_API}send_mail`,
      {
        message: msg,
      }
    );
    toast.success("Mail Sent successfully");
    console.log("PDF send request successful:", result);
    return result;
  } catch (e) {
    toast.error(`Error: ${e.message}`);
    console.error("Error sending PDF:", e.message);
  }
};

const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success("Copied to Clipboard");
    console.log("Text copied to clipboard:", text);
  } catch (err) {
    toast.error("Failed to copy text: ", err);
    console.error("Failed to copy text: ", err);
  }
};

const toggleSpeak = async (message) => {
  if (message.isSpeaking) {
    stopSpeaking();
    message.isSpeaking = false;
  } else {
    data.value.responseData.forEach((message) => {
      message.isSpeaking = false;
      message.isLoading = false;
    });
    message.isLoading = true;
    try {
      await speakText(message.message);
      message.isSpeaking = true;
    } catch (error) {
      console.error("Error speaking text:", error);
    } finally {
      message.isLoading = false;
    }
  }
};

const shareMessage = (message) => {
  if (navigator.share) {
    navigator
      .share({
        title: "Shared Message",
        text: message,
      })
      .then(() => {
        console.log("Share was successful.");
        toast.success("Share was successful.");
      })
      .catch((error) => {
        console.error("Sharing failed:", error);
        toast.error("Sharing failed:", error);
      });
  } else {
    console.error("Sharing is not supported on this browser.");
    toast.error("Sharing is not supported on this browser.");
  }
};

const getBtn = async () => {
  try {
    const result = await JWTinterceptor.get(
      `${process.env.VUE_APP_SOFA_API}messages_map`,
      {
        headers: {
          "x-access-tokens": token,
        },
      }
    );
    buttons.value = result.data;
    transformedButtons.value = buttons.value.map((btn) => {
      const capitalized = btn
        .replace(/_/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

      return { original: btn, label: capitalized };
    });
  } catch (error) {
    console.error("Error fetching buttons:", error);
  }
};
const handleButtonClick = (buttonMsg, buttonText) => {
  text.value = buttonText;
  text.value = buttonMsg;
  // Find the message that matches the button text
  const existingMessage = data.value.responseData.find(
    (msg) => msg.message === buttonText
  );

  if (existingMessage) {
    console.log('Found existing message with id:', existingMessage.id);
    const messageElement = document.querySelector(
      `[data-id="${existingMessage.id}"]`
    );

    if (messageElement) {
      messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      console.log('Scrolled to message with id:', existingMessage.id);
    } else {
      console.error('Message element with id', existingMessage.id, 'not found.');
    }
  } else {
  sendMsg(buttonMsg, buttonText); // If the message doesn't exist, send the new message
  console.log("Button clicked, message sent:", buttonMsg, buttonText);
  note.value = "";
  }
};

watch(
  () => store.state.topicId,
  (newId) => {
    console.log(newId)
    store.commit("setTopicId", newId);
    getTopicChat(newId);
  }
);
watch(
  () => navigationData.value.view,
  (newView) => {
    currentView.value = newView
  }
);
watch(data, (newVal, oldVal) => {
  if (newVal.responseData.length !== oldVal.responseData.length) {
    scrollToBottom();
  }
});
watch(note, (newValue) => {
  if (newValue !== '') {
    isNoteChanged.value = true;  // Set flag if there's unsaved data
  } else {
    isNoteChanged.value = false;
  }
});

const handleBeforeUnload = (event) => {
  if (isNoteChanged.value) {
    const message = 'You have unsaved data. Are you sure you want to leave?';
    event.returnValue = message; 
    return message; 
  }
};


window.addEventListener('beforeunload', handleBeforeUnload);


// Clean up the event listener when the component is unmounted
onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload);

});


onUnmounted(() => {
  store.dispatch("websocket/closeConnection");
  // Stop speaking if any message is currently being spoken
  data.value.responseData.forEach((msg) => {
    if (msg.isSpeaking) {
      stopSpeaking();
      msg.isSpeaking = false;
    }
  });
});
</script>

<style scoped>
.card {
  background-color: #f8f9fa;
}
.timestamp {
  font-size: 0.85rem;
  color: white;
}
.message-body {
  font-size: 1rem;
}
.button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: end;
  position: sticky;
  bottom: 0;
}
.message-color-user {
  background-color: #1f86ae;
}
.message-color-sofia .timestamp,
.message-color-sofia {
  background-color: #d1f8e5;
  color: #383939 !important;
}
.btn-color {
  color: #2481ad;
  background-color: transparent;
  border: none;
  font-size: 1.7rem;
  margin-right: 20px;
}

.dropdown-menu-up {
  top: auto;
  bottom: 100%;
  left: 0;
  transform: translateY(-10px);
  position: absolute;
  z-index: 1000;
}
ul {
  list-style-type: none;
  padding: 5px;
}
ul button {
  margin-bottom: 5px;
}
.dropup-menu {
  top: auto;
  bottom: 100%;
  left: 0;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.chat-container {
  max-height: 38rem;
  overflow-y: auto;
  padding: 10px;
  flex: 1;
}
</style>
