<template>
  <div class="container d-flex justify-content-center align-items-center min-vh-100">
    <div class="card p-4" style="max-width: 400px; width: 100%;">
      <div class="text-center mb-4">
        <img src="./../../assets/logo_sofia.png" alt="Logo" class="img-fluid">
      </div>
      <form @submit.prevent="login">
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input 
            v-model="email" 
            type="email" 
            id="email" 
            class="form-control" 
            placeholder="Enter your email" 
            required
            @blur="touched.email = true"
          />
          <small v-if="touched.email && !email" class="text-danger">Email is required.</small>
            <small v-else-if="touched.email && !isEmailValid" class="text-danger">Please enter a valid email address.</small>
        </div>

        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <div class="input-group">
            <input 
              v-model="password" 
              :type="showPassword ? 'text' : 'password'"
              id="password" 
              class="form-control" 
              placeholder="Enter your password" 
              required
              @blur="touched.password = true"
            />
            <div class="input-group-append">
              <span class="input-group-text text-muted bg-transparent border-left-0 rounded-right" style="height:100%;">
                <i @click="toggleShow" class="fas eye-shape"
                  :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
              </span>
            </div>
          </div>
          <small v-if="touched.password && !password" class="text-danger">Password is required.</small>
          <small v-else-if="touched.password && password.length < 6" class="text-danger">Password must be at least 6 characters.</small>

        </div>

        <div class="d-flex justify-content-between align-items-center mb-3">
          <a href="/forget-password" class="link-primary">Forget your password?</a>
          <button type="submit" class="btn btn-primary" :disabled="!isFormValid || loading">Login</button>
        </div>
        <button class="btn btn-primary" @click="loginWithEpic">Login with EPIC</button>

        
        <p class="text-center">Don't have an account? <a href="/register" class="link-primary">Signup</a></p>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref , computed, onMounted} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

const email = ref('');
const password = ref('');
const touched = ref({
  email: false,
  password: false,
});
const error = ref('');
const router = useRouter();
const store = useStore();
const loading = ref(false);
const toast = useToast();
let showPassword = ref(false);

// Epic FHIR Sandbox URLs
const EPIC_AUTH_URL = "https://fhir.epic.com/interconnect-fhir-oauth/oauth2/authorize";
const EPIC_TOKEN_URL = "https://fhir.epic.com/interconnect-fhir-oauth/oauth2/token";
const FHIR_API_BASE = "https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/R4";
const CLIENT_ID = "1ffa4495-c349-41b8-99cd-74f902f97d49";
const REDIRECT_URI = `http://localhost:8080/login`; // Change to your actual redirect URL
const AUD = "https://fhir.epic.com/interconnect-fhir-oauth/api/FHIR/R4/"; // Epic's FHIR base URL

const accessToken = ref(null);
// const doctorData  = ref(null);
// const state = crypto.randomUUID();
const loginWithEpic = () => {
  const authUrl = `${EPIC_AUTH_URL}?response_type=code&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&client_id=${CLIENT_ID}&aud=${encodeURIComponent(AUD)}&scope=openid`;
  window.location.href = authUrl;
};
// Step 2: Handle OAuth2 Redirect & Exchange Code for Access Token
const handleOAuthCallback = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  if (!code) return;

  try {
    const response = await fetch(EPIC_TOKEN_URL, {
      method:"POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        grant_type: "authorization_code",
        code: code,
        redirect_uri: REDIRECT_URI,
        client_id: CLIENT_ID
      }),
    });

    const data = await response.json();
    accessToken.value = data.access_token;
    console.log(data)

    if (accessToken.value) {
      fetchDoctorData();
    }
  } catch (error) {
    console.error("Error fetching access token:", error);
  }
};
const decodeJWT = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
};
const parseXMLToJSON = (xmlString) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, "text/xml");
  
  // Converting the XML structure to a JSON object
  const json = xmlToJson(xmlDoc);
  return json;
};

const xmlToJson = (xml) => {
  const obj = {};
  if (xml.nodeType === 1) { // element node
    if (xml.attributes.length > 0) {
      obj["@attributes"] = {};
      for (let i = 0; i < xml.attributes.length; i++) {
        const attribute = xml.attributes.item(i);
        obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
      }
    }
  } else if (xml.nodeType === 3) { // text node
    // eslint-disable-next-line no-const-assign
    obj = xml.nodeValue;
  }
  
  if (xml.hasChildNodes()) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      const item = xml.childNodes.item(i);
      const nodeName = item.nodeName;
      if (obj[nodeName] === undefined) {
        obj[nodeName] = xmlToJson(item);
      } else {
        if (Array.isArray(obj[nodeName])) {
          obj[nodeName].push(xmlToJson(item));
        } else {
          obj[nodeName] = [obj[nodeName], xmlToJson(item)];
        }
      }
    }
  }
  return obj;
};

// Step 3: Fetch Doctor (Provider) Data from Epic FHIR API
const fetchDoctorData = async () => {
  if (!accessToken.value) return;
  const decodedToken = decodeJWT(accessToken.value);
  const practitionerId = decodedToken.sub;

  try {
    const response = await fetch(`${FHIR_API_BASE}/Practitioner/${practitionerId}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${accessToken.value}` },
    });

    const xmlString = await response.text();
    const doctorData = parseXMLToJSON(xmlString);
    console.log(doctorData)
    alert(JSON.stringify(doctorData, null, 2));
    console.log("hi")
  } catch (error) {
    console.error("Error fetching doctor data:", error);
  }
};
// Run OAuth2 callback handling when component mounts
onMounted(() => {
  if (window.location.search.includes("code=")) {
    handleOAuthCallback();
  }
});

const isFormValid = computed(() => {
  return  isEmailValid.value &&  password.value.length >= 6 
});
const isEmailValid = computed(() => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email.value);
});
const toggleShow = () => {
            showPassword.value = !showPassword.value;
        };
const login = async () => {
  loading.value = true;
  error.value = '';

  try {
    await store.dispatch('auth/login', {
      email: email.value,
      password: password.value,
    });
    router.push('/');
  } catch (err) {
    if (err.response) {
      const statusCode = err.response.status;
      const message = err.response.data.message;
      if (statusCode === 401 && message == "user exists but not active") {
        
        router.push('/otp');
      } else if (statusCode === 400) {
        error.value = 'Please try again later.';
        toast.error("Please try again later.");
      } else if (statusCode === 401) {
        error.value = 'The password or email is incorrect.';
        toast.error("The password or email is incorrect.");
      } else {
        error.value = `Server error ${statusCode}`;
        toast.error(`Server error ${statusCode}`);

      }
    } else {
      error.value = 'Please try again later.';
    }
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.card {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.form-control {
  border-radius: 4px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.link-primary {
  color: #007bff;
}

.link-primary:hover {
  color: #0056b3;
}
</style>
